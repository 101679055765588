<template>
  <footer class="section-dark">
    <div class="footer">
      <div class="footer-link">
        <div class="footer-link-nav">
          <div class="footer-link-logo" @click="$router.push('/')">
            <el-image
              fit="cover"
              :src="require('../assets/logo.png')"
            ></el-image>
          </div>
          <div class="footer-link-menu">
            <div
              class="footer-link-menu-item"
              @click="$router.push('/case/changzhou')"
            >
              {{ $t("footer.case-changzhou") }}
            </div>
            <div class="footer-link-menu-item" @click="$router.push('/about')">
              {{ $t("footer.about") }}
            </div>
            <div class="footer-link-menu-item" @click="to_joinus()">
              {{ $t("footer.join") }}
            </div>
          </div>
        </div>
        <!-- <div class="footer-link-external">
          <div class="footer-link-external-item">
            <el-icon>
              <Select />
            </el-icon>
          </div>
          <div class="footer-link-external-item">
            <el-icon>
              <Select />
            </el-icon>
          </div>
        </div> -->
      </div>
      <div class="footer-bottom">
        <div class="footer-copyright">
          <div class="copyright">
            {{ $t("footer.copyright") }}
          </div>
          <div class="record" @click="to_icp">沪ICP备19021017号-1</div>
        </div>
        <!-- <div class="footer-language">
          <el-select
            v-model="value"
            @change="changeLanguage"
            placeholder="Select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
// import { nextTick } from "vue";
// import { Select } from "@element-plus/icons";
export default {
  name: "menu",
  props: {
    // isFlexible: Boolean,
  },
  components: {
    // Fold,
    // Select,
  },
  data() {
    return {
      show: false,
      menuAcitve: false,
      options: [
        {
          value: "zh",
          label: "简体中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
      value: localStorage.lang || "zh",
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    to_joinus() {
      let u = this._isMobile()
        ? "https://m.liepin.com/company/12207037/"
        : "https://liepin.com/company/12207037/";
      window.location.href = u;
    },
    to_icp(){
      window.location.href = 'https://beian.miit.gov.cn/';
    }
  },
};
</script>

<style lang="scss" scoped>
$main-color: #47a6db;
$main-bgcolor: #1a1a1a;
$font-color: #fff;
.section-dark {
  width: 100%;
  background-color: $main-bgcolor;
  color: #fff;
  position: relative;
  z-index: 10;
  padding: 90px 0;
}
.footer {
  @media (min-width: 768px) {
    width: 700px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  position: relative;
  z-index: 10;
  // margin-left: auto;
  margin: 0 auto;
  //   padding: 90px 0;
  .footer-link {
    // display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    .footer-link-nav {
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      display: flex;
      flex-direction: row;
      // margin: auto 0;
      .footer-link-logo {
        @media only screen and (max-width: 767px) {
          margin-right: 0;
        }
        display: flex;
        justify-content: center;
        // display: inline-block;
        margin-right: 40px;
        .el-image {
          // width:42px;
          height: 24px;
          transition: all 0.3s;
          opacity: 0.75;
        }
        .el-image:hover {
          opacity: 1;
        }
      }
      .footer-link-menu {
        // display: inline-block;
        font-weight: 400;
        vertical-align: baseline;

        .footer-link-menu-item {
          display: inline-block;
          padding: 0 12px;
          // padding-right: 24px;
          // height: 24px;
          line-height: 24px;
          transition: all 0.3s;
          opacity: 0.75;
          cursor: pointer;
        }
        .footer-link-menu-item:hover {
          opacity: 1;
        }
      }
    }
    .footer-link-external {
      display: flex;
      align-items: center;
      .footer-link-external-item {
        padding: 0 12px;
        height: 24px;
        cursor: pointer;
        .el-icon {
          font-size: 24px;
        }
      }
    }
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    .footer-copyright {
      display: flex;
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.75);
      .copyright {
        padding-right: 16px;
      }
      .record{
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .footer-language {
      position: relative;
      display: flex;
      align-items: center;
      // height: 24px;
      padding: 0 8px;
      border-radius: 4px;
      // transition: all 0.3s;
      cursor: pointer;
      .el-select {
        margin-left: 20px;
        width: 120px;
      }
    }
    // .footer-language:hover {
    //   background-color: rgba(255, 255, 255, 0.09);
    //   border-radius: 4px;
    // }
  }
}
</style>